import {
    typePending,
    typeComplete,
    pendingReducer,
    typeFail,
    completeReducer,
    failureReducer,
    passReducer,
} from 'state-domains/utils';

import { BaseAction } from '../../../../types';
import {
    LOAD_FILTERS,
    UPDATE_DIGEST,
    CREATE_FILTERS,
    UPDATE_FILTERS,
    DELETE_FILTERS,
    LOAD_RESOURCES,
    CREATE_RESOURCES,
    DELETE_RESOURCES,
    LOAD_DIGEST,
} from '../../../../types/actionTypes';
import { mergeObjectAtLevel } from '../../../utils';
import {
    NotificationsState,
    ResourcesPayload,
    FiltersPayload,
    ResourceState,
    FilterState,
    FilterQueryProps,
    DigestState,
    DigestPreference,
} from '../../types';

export const RESOURCE_STATE: ResourceState = pendingReducer({
    map: {},
});

export const FILTER_STATE: FilterState = pendingReducer({
    map: {},
});

export const DIGEST_STATE: DigestState = pendingReducer({
    digestPreference: null,
});

function updateResourceMap(
    state: NotificationsState,
    action: BaseAction<ResourcesPayload>,
): NotificationsState {
    const {
        payload: { unsubscribers },
    } = action;
    const {
        resourceState: { map },
    } = state;

    const newMap = { ...map };
    unsubscribers.forEach(({ id, resource, self }) => {
        newMap[resource] = completeReducer({ id, resource, self });
    });
    const resourceState = completeReducer({ ...state.resourceState, map: newMap });
    return { ...state, resourceState };
}

function resourcesFailureReducer(state: NotificationsState, action: BaseAction) {
    const { payload: err } = action;

    const { resourceState } = state;

    const nextResourcesState = failureReducer(resourceState);
    nextResourcesState.error = err;

    return { ...state, resourceState: nextResourcesState };
}

function deleteResourcePending(
    state: NotificationsState,
    _action: BaseAction<{ resource: string }>,
): NotificationsState {
    return {
        ...state,
        resourceState: pendingReducer(state.resourceState),
    };
}

function deleteResourceComplete(state: NotificationsState, action: BaseAction): NotificationsState {
    const {
        payload: { resource },
    } = action;
    const {
        resourceState: { map },
    } = state;
    const { [resource]: _deleted, ...other } = map;
    const resourceState = completeReducer({ ...state.resourceState, map: other });
    return { ...state, resourceState };
}

function pendingResource(state: NotificationsState, _action: BaseAction<any>): NotificationsState {
    return { ...state, resourceState: pendingReducer(state.resourceState) };
}

function getFilterKey({ resource, topic, channel }: FilterQueryProps) {
    return `${resource}${topic}${channel}`;
}

function updateFilterMap(
    state: NotificationsState,
    action: BaseAction<FiltersPayload>,
): NotificationsState {
    const { filters } = action.payload;
    const filterMap = { ...state.filterState.map };

    filters.forEach((filter) => {
        const filterKey = getFilterKey(filter);
        filterMap[filterKey] = completeReducer(filter);
    });
    return mergeObjectAtLevel(state, ['filterState'], completeReducer({ map: filterMap }));
}

function filterPendingReducer(
    state: NotificationsState,
    _action: BaseAction<FiltersPayload>,
): NotificationsState {
    return {
        ...state,
        filterState: pendingReducer(state.filterState),
    };
}

function digestPending(state: NotificationsState): NotificationsState {
    return {
        ...state,
        digestState: pendingReducer({
            ...state.digestState,
            error: null,
        }),
    };
}

function digestComplete(
    state: NotificationsState,
    action: BaseAction<{ digestPreference?: DigestPreference }>,
): NotificationsState {
    const { digestPreference } = action.payload;
    return {
        ...state,
        digestState: completeReducer({
            ...state.digestState,
            error: null,
            digestPreference: digestPreference ?? null,
        }),
    };
}

function digestFail(state: NotificationsState, action: BaseAction): NotificationsState {
    const { error } = action.payload;
    return {
        ...state,
        digestState: failureReducer({
            ...state.digestState,
            error,
        }),
    };
}

export const reducer = {
    [typePending(LOAD_FILTERS)]: filterPendingReducer,
    [typeComplete(LOAD_FILTERS)]: updateFilterMap,
    [typeFail(LOAD_FILTERS)]: passReducer,

    [typePending(UPDATE_FILTERS)]: filterPendingReducer,
    [typeComplete(UPDATE_FILTERS)]: updateFilterMap,
    [typeFail(UPDATE_FILTERS)]: passReducer,

    [typePending(UPDATE_DIGEST)]: digestPending,
    [typeComplete(UPDATE_DIGEST)]: digestComplete,
    [typeFail(UPDATE_DIGEST)]: digestFail,

    [typePending(LOAD_DIGEST)]: digestPending,
    [typeComplete(LOAD_DIGEST)]: digestComplete,
    [typeFail(LOAD_DIGEST)]: digestFail,

    [typePending(CREATE_FILTERS)]: filterPendingReducer,
    [typeComplete(CREATE_FILTERS)]: updateFilterMap,
    [typeFail(CREATE_FILTERS)]: passReducer,

    [typePending(DELETE_FILTERS)]: filterPendingReducer,
    [typeComplete(DELETE_FILTERS)]: passReducer,
    [typeFail(DELETE_FILTERS)]: passReducer,

    [typePending(LOAD_RESOURCES)]: pendingResource,
    [typeComplete(LOAD_RESOURCES)]: updateResourceMap,
    [typeFail(LOAD_RESOURCES)]: resourcesFailureReducer,

    [typePending(CREATE_RESOURCES)]: passReducer,
    [typeComplete(CREATE_RESOURCES)]: updateResourceMap,
    [typeFail(CREATE_RESOURCES)]: passReducer,

    [typePending(DELETE_RESOURCES)]: deleteResourcePending,
    [typeComplete(DELETE_RESOURCES)]: deleteResourceComplete,
    [typeFail(DELETE_RESOURCES)]: passReducer,
};
